<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
      strModel="Configuracoes/Certificado"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getCertificado"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getCertificado();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Documento",
          sortable: true,
          value: "strDocumento",
        },
        {
          text: "Validade do Certificado",
          sortable: true,
          value: "strDataValidade",
        },
        {
          text: "Empresas vinculadas",
          sortable: true,
          value: "strEmpresa",
          collapse: 1,
          collapseLabel: "Visualizar",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{}
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "configuracoes.certificado.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "configuracoes.certificado.edit",
        params: { intId: data.intId },
      });
    },

    getCertificado() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get("Configuracoes/Certificado", this.formFiltros)
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objCertificado) => {
              let arrayEmpresa = [];
              let strMensagem = "";

              if (objCertificado.intDiasValidade > 0) {
                strMensagem =
                  ' <span style="color:green">Vence em ' +
                  objCertificado.intDiasValidade +
                  " dia(s)</span>";
              } else if (objCertificado.intDiasValidade == 0) {
                strMensagem = ' <span style="color:orange">Vence hoje</span>';
              } else {
                strMensagem =
                  ' <span style="color:red">Vencido a ' +
                  objCertificado.intDiasValidade +
                  " dias</span>";
              }

              objCertificado.empresas.forEach((element) => {
                arrayEmpresa.push(
                  element.empresa.strNome + " " + element.empresa.strCnpj
                );
              });
              let item = {
                intId: objCertificado.intId,
                strDocumento: objCertificado.strDocumento,
                strNome: objCertificado.strNome,
                strEmpresa: arrayEmpresa.join("<br>"),
                strDataValidade:
                  this.$utilidade.toDate(objCertificado.strDataValidade, true) +
                  strMensagem,
                _item: objCertificado,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
